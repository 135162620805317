import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import clsx from 'clsx';
import { addMinutes, format, parseISO } from 'date-fns';
import useSWR, { useSWRConfig } from 'swr';

import { CopyableLink } from '../CopyableLink';
import { EventLogItem } from '../EventLog';
import { api } from '../api';
import { useGifts } from '../gift';
import { useBefore, useCountdownAction, useTimeCountdown } from '../hooks/time';
import { LiveSend } from '../live/LiveSend';
import { AdminEvent, Gift } from '../types';
import { LOGIN_URL } from '../url';
import { AdminChatSend } from './AdminChatSend';
import { AdminEventHeader } from './AdminEventHeader';
import { Alert } from './Alert';

const GiftIcon: React.FC<{ gift: Gift }> = ({ gift }) => {
  const GIFT_TYPES = useGifts();

  if (!GIFT_TYPES) return null;
  const image = GIFT_TYPES.find((g) => g.type === gift.item)?.image;
  return <img src={image} alt={gift.item} className="h-6" />;
};

export const Countdown: React.FC<{ limit: Date }> = ({ limit }) => {
  const count = useTimeCountdown(limit);
  return <span>{count}</span>;
};

const ParticipantList: React.FC<{ ceremony: AdminEvent }> = (props) => {
  const participants = props.ceremony.participants
    .map((p) => ({
      ...p,
      gifts: props.ceremony.gifts
        .filter((g) => g.name === p.name)
        .sort((a, b) => a.amount - b.amount),
    }))
    .map((p) => ({
      ...p,
      totalPrice: p.gifts.reduce((a, b) => a + b.amount, 0),
    }))
    .sort((a, b) => b.totalPrice - a.totalPrice);
  return (
    <div>
      <h3 className="text-xl font-bold">Participants</h3>
      <ul>
        {participants.map((p) => (
          <li
            className="flex items-center gap-2 border-b border-gray-300 p-2"
            key={p.name}
          >
            {p.name} ({p.realname})
            {p.gifts.map((g) => (
              <GiftIcon gift={g} />
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};
const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventRealtime: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: event, error } = useSWR<AdminEvent>(
    `/manager/ceremony/${id}/`,
    fetcher,
    { refreshInterval: 3 },
  );
  const { mutate } = useSWRConfig();

  const [activeTab, setActiveTab] = React.useState<'log' | 'participant'>(
    'log',
  );

  // If the pamyment is not completed, redirect to payment page
  useEffect(() => {
    if (event && !event.is_payment_completed) {
      navigate(`/manager/event/${event.id}/payment/`);
    }
    if (event && event.event_status === 'finished') {
      navigate(`/manager/event/${event.id}/after/`);
    }
    if (event && !event.is_setup_completed) {
      navigate(`/manager/event/${event?.id}/setup/`);
    }
  }, [event, navigate]);

  const startEvent = async () => {
    await api.post(`/manager/ceremony/${id}/start/`);
    mutate(`/manager/ceremony/${id}/`);
  };
  const { start: startCountdown, count } = useCountdownAction(startEvent, 15);

  const stopEvent = async () => {
    if (
      !window.confirm(
        'イベントを終了しますか？\n一度終了すると、再開できません。',
      )
    ) {
      return;
    }
    await api.post(`/manager/ceremony/${id}/finish/`);
    await mutate(`/manager/ceremony/${id}/`);

    // ライブ終了が面倒なので楽な方法で
    window.location.reload();
  };

  const beforeStartsAt = useBefore(parseISO(event?.starts_at || ''));

  // guard
  if (error) {
    if (error.response?.status === 401) {
      window.location.href = LOGIN_URL;
      return null;
    }
    if (error.response?.status === 403) {
      return <div>権限がありません</div>;
    }
    if (!event) {
      return <div>エラーが発生しました</div>;
    }
  }
  if (!event) {
    return <div>Loading...</div>;
  }

  const inviteLink = `${window.location.origin}/ceremony/${event.token}/`;

  return (
    <div className="p-4">
      <AdminEventHeader active="realtime" event={event} />
      <div className="mx-auto max-w-screen-md">
        <div className="text-center">
          <p>参加者には以下のリンクを共有してください。</p>
          <CopyableLink link={inviteLink} />
          <hr className="my-1" />
        </div>
        <AdminEventRealtimeStatusInfo
          event={event}
          countdownUntilLiveStart={count}
          stopEvent={stopEvent}
        />
        <div className="sm:flex">
          <div className="flex-1">
            {!['running_after', 'finish'].includes(event.event_status) &&
              id && (
                <>
                  <LiveSend
                    channel={id}
                    canStartEvent={event.starts_at !== null && !beforeStartsAt}
                    isEventStarted={event.event_status === 'running'}
                    eventStartHandler={startCountdown}
                    onStartCountdown={!!count}
                  />
                </>
              )}
          </div>
          <div className="flex-1">
            <div className="m-2 flex">
              <button
                type="button"
                onClick={() => setActiveTab('log')}
                className={clsx(
                  'flex-1 rounded-t border-blue-500 py-2 outline-none',
                  activeTab === 'log' ? 'border-x border-t' : 'border-b',
                )}
              >
                イベントログ
              </button>
              <button
                type="button"
                onClick={() => setActiveTab('participant')}
                className={clsx(
                  'flex-1 rounded-t border-blue-500 py-2 outline-none',
                  activeTab === 'participant'
                    ? 'border-x border-t'
                    : 'border-b',
                )}
              >
                参加者一覧
              </button>
            </div>
            {activeTab === 'log' && (
              <>
                <AdminChatSend event={event} className="m-2" />
                <h3 className="mb-2 mt-4 text-xl font-bold">イベントログ</h3>
                {event.logs
                  .slice()
                  .reverse()
                  .map((log) => (
                    <div key={log.timestamp} className="mx-2 my-1">
                      <EventLogItem
                        log={log}
                        participants={event.participants}
                      />
                    </div>
                  ))}
              </>
            )}
            {activeTab === 'participant' && (
              <div className="p-2">
                <ParticipantList ceremony={event} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminEventRealtimeStatusInfo: React.FC<{
  event: AdminEvent;
  countdownUntilLiveStart: number | null;
  stopEvent: () => void;
}> = ({ event, countdownUntilLiveStart: count, stopEvent }) => {
  const beforeStartsAt = useBefore(parseISO(event.starts_at!));
  if (event.event_status === 'running_before' && event.starts_at) {
    return (
      <Alert variant="success">
        {beforeStartsAt ? (
          <>
            <p className="my-1">
              イベント開始30分前になりました。参加者は紹介画像・紹介動画を見ることができます。
            </p>
            <p className="my-1">
              開始時刻の
              <span className="font-bold">
                {format(parseISO(event.starts_at), 'HH:mm')}
              </span>
              になったら、イベントを開始することができます。
            </p>
            <p className="py-2">
              開始時刻まであと
              <span className="text-xl font-bold">
                <Countdown limit={parseISO(event.starts_at)} />
              </span>
            </p>
          </>
        ) : (
          <>
            {count ? (
              <p>
                開始まであと
                <span className="text-2xl font-bold">{count} 秒</span>
              </p>
            ) : (
              <>
                <p>イベント開始時刻になりました。</p>
                <p>カメラをオンにし、ライブ配信を開始してください。</p>
              </>
            )}
          </>
        )}
      </Alert>
    );
  }
  if (event.event_status === 'running_after') {
    return (
      <Alert variant="success">
        <p>イベントは終了しました。</p>
        <p>※イベント終了から10分間、メッセージ送信・プレゼント贈呈が可能です</p>
      </Alert>
    );
  }
  if (
    event.event_status === 'running' &&
    event.started_at &&
    event.event_length
  ) {
    return (
      <div className="bg-green-200 p-1 text-center">
        <p>
          イベント開催中 終了まで残り
          <span className="text-xl font-bold">
            <Countdown
              limit={addMinutes(
                parseISO(event.started_at!),
                event.event_length!,
              )}
            />
          </span>
        </p>
        <div className="text-center">
          <button
            type="button"
            onClick={stopEvent}
            className="my-1 rounded bg-red-600 px-2 py-1 text-sm text-white"
          >
            イベントを終了
          </button>
        </div>
      </div>
    );
  }
  if (event.event_status === 'not_started') {
    return (
      <Alert variant="info">
        <p>イベントは現在開催されていません。</p>
        <p>
          開始時刻 ({format(parseISO(event.starts_at!), 'yyyy/MM/dd HH:mm')})
          までお待ちください。
        </p>
        <p className="mt-2">なお、イベント開始30分前から開場されます。</p>
      </Alert>
    );
  }
  if (event.event_status === 'finished') {
    return (
      <Alert variant="error">
        <p>このイベントは終了しました</p>
      </Alert>
    );
  }
  return null;
};
