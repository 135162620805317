import React from 'react';

import Bg from './img/background/bg_piano.png';

export const TopPagePiano: React.FC = () => {
  return (
    <>
      <div
        className="bg-cover p-4"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundColor: 'rgba(255,255,255,0.5)',
          backgroundBlendMode: 'lighten',
        }}
      >
        <div
          className="mx-auto bg-cover p-4"
          style={{
            maxWidth: '400px',
          }}
        >
          <div className="mb-4 text-center">
            <div className="flex justify-end">
              <div className="rounded px-1">🇯🇵</div>
              <div className="rounded px-1 bg-blue-400">🇺🇸</div>
              <div className="rounded px-1">🇨🇳</div>
              <div className="rounded px-1">🇰🇷</div>
            </div>
            <div className="text-center text-3xl my-2 font-bold">
              PASONA 音楽配信サイト
            </div>
            <div className="my-1 text-center">You are invited to the event</div>
          </div>
          <div className="my-2 block w-full rounded border border-gray-300 bg-white px-4 py-1 text-center shadow">
            <h2 className="my-2 text-xl font-bold">動作環境</h2>
            <p>
              動作ブラウザは、Androidの場合はGoogle Chrome,
              iPhoneの場合はSafariを推奨しています。
              推奨環境以外のブラウザでの動作は保証しておりません。
            </p>
            <p>PCの場合はGoogle Chromeの最新版を推奨しています。</p>
          </div>
          <div className="text-center">
            <a href="/accounts/login" className="text-blue-500">
              ログイン
            </a>
            または
            <a href="/accounts/signup" className="text-blue-500">
              登録
            </a>
          </div>
        </div>
      </div>
      <div className="bg-gray-500 py-4 text-center text-sm text-white">
        <div className="py-1">
          <a href="/about_us">運営会社概要</a>
        </div>
        <div className="py-1">
          <a href="/tos">利用規約</a>
        </div>
        <div className="py-1">
          <a href="/tokusho">特定商取引法に基づく表記</a>
        </div>
        <div className="py-1">
          <a href="/privacy">プライバシーポリシー</a>
        </div>
      </div>
    </>
  );
};
