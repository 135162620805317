import React from 'react';

import { Ceremony } from '../types';

export const ParticipantAnnouncePre: React.FC<{ ceremony: Ceremony }> = ({
  ceremony,
}) => <ParticipantAnnounce ceremony={ceremony} variant="pre" />;

export const ParticipantAnnouncePost: React.FC<{ ceremony: Ceremony }> = ({
  ceremony,
}) => <ParticipantAnnounce ceremony={ceremony} variant="post" />;

export const ParticipantAnnounce: React.FC<{
  ceremony: Ceremony;
  variant: 'pre' | 'post';
}> = ({ ceremony, variant }) => {
  const announce =
    variant === 'pre' ? ceremony.announce_pre : ceremony.announce_post;

  return (
    <div className="flex h-full flex-col">
      <div
        className="m-1 flex-1 overflow-y-auto border border-gray-600 bg-white/50 p-2"
        style={{ minWidth: 200 }}
      >
        <p className="mb-1 font-bold">Message from site administator</p>
        <p className="my-1 text-sm">
          {variant === 'pre' && <AdminAnnouncePre ceremony={ceremony} />}
          {variant === 'post' && <AdminAnnouncePost ceremony={ceremony} />}
        </p>
      </div>
      {announce && (
        <div
          className="mx-1 flex-1 overflow-y-auto border border-gray-700 bg-white/50 p-2"
          style={{ minWidth: 200 }}
        >
          <p className="my-1 font-bold">Message from organizer</p>
          <p className="my-1 whitespace-pre-wrap text-sm">{announce}</p>
        </div>
      )}
    </div>
  );
};

const AdminAnnouncePre: React.FC<{ ceremony: Ceremony }> = ({ ceremony }) => {
  return <>Thank you for joining {ceremony.pet_name}'s recital today!</>;
};

const AdminAnnouncePost: React.FC<{ ceremony: Ceremony }> = ({ ceremony }) => {
  return <>Thank you for joining {ceremony.pet_name}'s recital today!</>;
};
