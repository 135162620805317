import { ComponentPropsWithoutRef, ReactElement, forwardRef } from 'react';

import clsx from 'clsx';

const RequiredBadge = (): ReactElement => (
  <div className="ml-1 inline-block rounded-sm bg-red-500 px-1 text-xs text-white">
    Required
  </div>
);
const NotRequiredBadge = (): ReactElement => (
  <div className="ml-1 inline-block rounded-sm bg-gray-500 px-1 text-xs text-white">
    Optional
  </div>
);
const FormRow = (props: {
  label: string;
  required?: boolean;
  message?: string;
  error?: string;
  children: ReactElement;
}): ReactElement => (
  <div className="my-3">
    <div className="text-sm">
      {props.label}
      {props.required ? <RequiredBadge /> : <NotRequiredBadge />}
    </div>
    {props.children}
    {props.message && (
      <div className="text-xs text-gray-500">{props.message}</div>
    )}
    {props.error && (
      <div className="text-sm font-bold text-red-500">{props.error}</div>
    )}
  </div>
);
const FormInput = forwardRef<
  HTMLInputElement,
  { hasError?: boolean } & ComponentPropsWithoutRef<'input'>
>(
  (props, ref): ReactElement => (
    <input
      className={clsx(
        'my-1 block w-full rounded-sm border border-gray-400 p-1 shadow',
        props.hasError && 'border-red-500 bg-red-100',
      )}
      {...props}
      ref={ref}
    />
  ),
);
export const FormRowInput = forwardRef<
  HTMLInputElement,
  {
    label: string;
    required?: boolean;
    message?: string;
    error?: string;
  } & ComponentPropsWithoutRef<'input'>
>(
  (props, ref): ReactElement => (
    <FormRow
      label={props.label}
      required={props.required}
      message={props.message}
      error={props.error}
    >
      <FormInput
        type="text"
        placeholder={props.label}
        hasError={props.error !== undefined}
        {...props}
        ref={ref}
      />
    </FormRow>
  ),
);
