import { ReactElement, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { format, parseISO } from 'date-fns';

import { FormRowInput } from '../Form';
import { Ceremony } from '../types';

export const CeremonyParticipantJoin = (props: {
  ceremony: Ceremony;
  submitNames: (
    realname: string,
    nickname: string,
    email?: string,
  ) => Promise<void>;
}): ReactElement => {
  type Inputs = {
    realname: string;
    nickname: string;
    email: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const paymentFormRef = useRef<HTMLFormElement>(null);

  const onSubmit = async (data: Inputs) => {
    await props.submitNames(data.realname, data.nickname, data.email);
    paymentFormRef.current?.submit();
  };

  return (
    <div className="mx-auto p-4" style={{ maxWidth: 400 }}>
      <div className="flex justify-end">
        <div className="rounded px-1">🇯🇵</div>
        <div className="rounded px-1 bg-blue-400">🇺🇸</div>
        <div className="rounded px-1">🇨🇳</div>
        <div className="rounded px-1">🇰🇷</div>
      </div>
      <div className="text-center text-3xl my-2 font-bold">
        PASONA 音楽配信サイト
      </div>
      <div className="my-1 text-center">You are invited to the event</div>
      <div className="mb-1 text-center text-3xl font-bold">
        {props.ceremony.event_name}
      </div>
      <div className="m-1 text-center">
        {props.ceremony.starts_at &&
          format(parseISO(props.ceremony.starts_at), 'yyyy/MM/dd HH:mm')}
        ~
      </div>
      <div className="my-2 border border-gray-400 p-2 shadow">
        <div>Fill out this form to join this event.</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRowInput
            label="Real name"
            message="※ Only the event organizer can see your real name."
            required
            error={errors && errors.realname && 'Please input your real name'}
            {...register('realname', { required: true })}
          />
          <FormRowInput
            label="Nickname"
            message="※ Any participant can see your nickname."
            required
            error={
              errors && errors.nickname && 'ニックネームを入力してください'
            }
            {...register('nickname', { required: true })}
          />
          <FormRowInput
            label="Email"
            message="※ Only the event organizer can see your email address."
            {...register('email', {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Please input a valid email address',
              },
            })}
            error={errors && errors.email && errors.email.message}
          />
          <div className="text-xs text-gray-500">
            Event organizer will send you a thank you message or special gift
            video after the event, if you input your email address.
          </div>
          <button
            className="my-2 block w-full rounded-sm bg-orange-800 px-4 py-1 text-white shadow"
            type="submit"
          >
            Pay entrance fee (3,000 JPY) to join
          </button>
        </form>
      </div>
      <div className="my-2 block w-full rounded border border-red-300 bg-red-100 px-4 py-1 text-center shadow">
        <p>
          Google Chrome (Android)、Safari (iPhone) are recommended. We do not
          guarantee the operation in browsers other than the recommended
          environment.
        </p>
        <p>
          In the case of a PC, we recommend the latest version of Google Chrome.
        </p>
      </div>
      <form action="/payment/confirm/" method="POST" ref={paymentFormRef}>
        <input type="hidden" name="amount" value="3000" />
        <input type="hidden" name="kind" value="entrance-fee" />
        <input type="hidden" name="overview" value="入場料" />
        <input type="hidden" name="payload" value="{}" />
        <input
          type="hidden"
          name="callback"
          value={window.location.href + '?paid'}
        />
      </form>
    </div>
  );
};
