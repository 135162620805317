import clsx from 'clsx';

import { GiftIcon } from './GiftIcon';
import { UserIcon } from './UserIcon';
import { Loading } from './admin/Loading';
import { useGifts } from './gift';
import { EventLog, Participant } from './types';

export const EventLogItem: React.FC<{
  log: EventLog;
  participants: Participant[];
}> = ({ log, participants }) => {
  const GIFT_TYPES = useGifts();
  if (!GIFT_TYPES) return <Loading />;
  switch (log.type) {
    case 'join':
      return (
        <div className="flex">
          <UserIcon
            name={log.name}
            participants={participants}
            className="h-8 w-8"
          />
          <div className="inline-block flex items-start rounded-lg bg-white/75 px-2 py-1 italic text-black">
            {log.name} joined
          </div>
        </div>
      );
    case 'gift':
      return (
        <div className="flex">
          <UserIcon
            name={log.name}
            participants={participants}
            className="h-8 w-8"
          />
          <div className="inline-block rounded-lg bg-white/75 px-2 py-1 text-black">
            <div className="italic">{log.name} sent a gift!</div>
            <div>
              {log.gifts.map((gift) => (
                <GiftIcon
                  gift={{ ...gift, name: log.name }}
                  className="inline-block h-8 w-8"
                />
              ))}
            </div>
          </div>
        </div>
      );
    case 'message':
      return (
        <div className="flex">
          <UserIcon
            name={log.name}
            participants={participants}
            className="h-8 w-8"
          />
          <div className="inline-block items-start rounded-lg bg-white/75 px-2 py-1 text-black">
            <div
              className={clsx(
                'mr-2 text-xs',
                log.is_manager && 'rounded bg-yellow-500 px-1',
              )}
            >
              {log.name}
              {log.is_manager && '(Organizeer)'}
            </div>
            <div className="break-all">{log.message}</div>
          </div>
        </div>
      );
    case 'start':
      return (
        <div className="flex">
          <div className="inline-block flex items-start rounded-lg border border-black bg-white/75 px-2 py-1 italic text-black">
            Event started
          </div>
        </div>
      );
    default:
      return null;
  }
};
